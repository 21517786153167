
import { defineComponent } from "vue";

export default defineComponent({
  name: "MobileTheoryBlock",
  props: {
    to: {
      default: () => "/",
    },

    goTo: {
      default: () => null,
    },
  },
});

import { useStore } from "vuex";
import { computed } from "vue";

export default () => {
  const store = useStore();

  const getLinks = async (drivingSchoolId: number) => {
    await store.dispatch("driving-school/links/findOne", {
      resource: `/driving-schools/drive-buzz-onboarding-links/${drivingSchoolId}`,
    });
  };

  const links = computed(() => store.getters["driving-school/links/getDataItem"]);

  return {
    getLinks,
    links,
  };
};

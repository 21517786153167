import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_overlay = _resolveComponent("b-overlay")!
  const _directive_media = _resolveDirective("media")!

  return (_openBlock(), _createBlock(_component_b_overlay, {
    show: _ctx.isLoading,
    rounded: "sm",
    opacity: "1"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("img", {
        class: _normalizeClass([_ctx.styleClass, "mb-4"]),
        style: {"width":"200px","height":"auto"},
        src: "",
        alt: "",
        ref: "logo"
      }, null, 2), [
        [_directive_media, {
        url: _ctx.avatarUrl,
        noFetch: !_ctx.hasId,
        fail: _ctx.handleError,
        auth: true,
        httpCache: false,
        loading: _ctx.onLoading,
        success: _ctx.onSuccess,
      }]
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.customLinks.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customLinks, (link) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (link.href)
              ? (_openBlock(), _createElementBlock("a", {
                  href: link.href,
                  class: "bg-monza-500 rounded-circle drive-buzz-icon",
                  target: "_blank",
                  key: link.title
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(["fa-brands text-white font-size-30", link.icon])
                  }, null, 2)
                ], 8, _hoisted_2))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}